import Chainable from "./Chainable.mts";

type Address = {
  // Number + street
  addressLine1: string;
  // Apt, Ste, etc...
  addressLine2?: string;
  // City/town
  locality: string;
  // State/province
  subdivision: string;
  // Zip code
  postalCode: string;
  country: Address.Country | (string & {});
};

namespace Address {
  export type Country = "CA" | "US";
  export const format = Chainable.create(
    Chainable.wrapFactory(
      ({ omit = [] as Extract<keyof Address, "addressLine2">[] }) =>
        (address: Address) =>
          !address
            ? ""
            : `${address.addressLine1}${
                !omit.includes("addressLine2") && address.addressLine2
                  ? `, ${address.addressLine2}`
                  : ""
              }, ${address.locality} ${address.subdivision} ${address.postalCode}`,
    ),
    {
      omit: (fields: Extract<keyof Address, "addressLine2">[]) => ({
        omit: fields,
      }),
    },
  );
}

export default Address;
